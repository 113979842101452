export const gameL10n = {
  "English": {
    "Menu": "Menu",
    "Player count": "Player count",
    "Layout": "Layout",
    "Touch mode": "Touch mode",
    "Touch/mouse mode": "Touch/mouse mode",
    "Enable": "Enable",
    "Disable": "Disable",
    "Load": "Load",
    "Load quest": "Load quest",
    "Load campaign cards": "Load campaign cards",
    "Load deck (OCTGN file)": "Load deck (OCTGN file)",
    "Load deck (RingsDB URL)": "Load deck (RingsDB URL)",
    "Load game (.json)": "Load game (.json)",
    "Load custom cards (.txt)": "Load custom cards (.txt)",
    "Unload": "Unload",
    "Unload my deck": "Unload my deck",
    "Unload encounter": "Unload encounter",
    "Spawn card": "Spawn card",
    "From the card pool": "From the card pool",
    "Create your own card": "Create your own card",
    "Random": "Random",
    "Coin": "Coin",
    "Number": "Number",
    "Options": "Options",
    "Adjust card size": "Adjust card size",
    "Cards per round": "Cards per round",
    "Battle quest": "Battle quest",
    "Siege quest": "Siege quest",
    "Normal quest": "Normal quest",
    "Special functions": "Special functions",
    "To Catch an Orc Setup": "To Catch an Orc Setup",
    "Escape from Mount Gram Seteup": "Escape from Mount Gram Seteup",
    "The Fortress of Nurn Setup": "The Fortress of Nurn Setup",
    "Glittering Caves Clues": "Glittering Caves Clues",
    "Download": "Download",
    "Game state (.json)": "Game state (.json)",
    "Export cards (.txt)": "Export cards (.txt)",
    "Reload decks": "Reload decks",
    "Clear table": "Clear table",
    "Close room": "Close room",
    "Mark as victory": "Mark as victory",
    "Mark as defeat": "Mark as defeat",
    "Mark as incomplete": "Mark as incomplete",
    "View": "View",
    "Hotkeys": "Hotkeys",
    "Spectators": "Spectators",
    "Quest Companion": "Quest Companion",
    "Shared": "Shared",
    "Player 1": "Player 1",
    "Player 2": "Player 2",
    "Player 3": "Player 3",
    "Player 4": "Player 4",
    "player1": "Player 1",
    "player2": "Player 2",
    "player3": "Player 3",
    "player4": "Player 4",
    "Encounter Deck": "Encounter Deck", 
    "Encounter Discard": "Encounter Discard", 
    "Discard": "Discard",
    "Quest Deck": "Quest Deck",
    "Quest Discard": "Quest Discard",
    "Second Quest Deck": "Second Quest Deck", 
    "Quest Deck 2": "Quest Deck 2",
    "Second Quest Discard": "Second Quest Discard", 
    "Quest Discard 2": "Quest Discard 2",
    "Second Encounter Deck": "Second Encounter Deck", 
    "Encounter Deck 2": "Encounter Deck 2",
    "Second Encounter Discard": "Second Encounter Discard", 
    "Encounter Discard 2": "Encounter Discard 2",
    "Third Encounter Deck": "Third Encounter Deck", 
    "Encounter Deck 3": "Encounter Deck 3",
    "Third Encounter Discard": "Third Encounter Discard", 
    "Encounter Discard 3": "Encounter Discard 3",
    "Other": "Other", 
    "Set Aside": "Set Aside", 
    "Victory Display": "Victory Display", 
    "Staging Area": "Staging Area", 
    "Active Location": "Active Location", 
    "Active": "Active",
    "Main Quest": "Main Quest", 
    "Extra 1": "Extra 1", 
    "Extra 2": "Extra 2", 
    "Extra 3": "Extra 3", 
    "Hand": "Hand",
    "Deck": "Deck",
    "Play Area": "Play Area",
    "Engaged": "Engaged",
    "Player 1 Hand": "Player 1 Hand", 
    "Player 1 Deck": "Player 1 Deck", 
    "Player 1 Discard": "Player 1 Discard", 
    "Player 1 Sideboard": "Player 1 Sideboard", 
    "Player 1 Deck 2": "Player 1 Deck 2", 
    "Player 1 Play Area 1": "Player 1 Play Area 1",
    "Player 1 Play Area 2": "Player 1 Play Area 2", 
    "Player 1 Play Area 3": "Player 1 Play Area 3", 
    "Player 1 Play Area 4": "Player 1 Play Area 4", 
    "Player 1 Engaged Area": "Player 1 Engaged Area", 
    "Player 1 Current Event": "Player 1 Current Event", 
    "Player 2 Hand": "Player 2 Hand", 
    "Player 2 Deck": "Player 2 Deck", 
    "Player 2 Discard": "Player 2 Discard", 
    "Player 2 Sideboard": "Player 2 Sideboard", 
    "Player 2 Deck 2": "Player 2 Deck 2", 
    "Player 2 Play Area 1": "Player 2 Play Area 1",
    "Player 2 Play Area 2": "Player 2 Play Area 2", 
    "Player 2 Play Area 3": "Player 2 Play Area 3", 
    "Player 2 Play Area 4": "Player 2 Play Area 4", 
    "Player 2 Engaged Area": "Player 2 Engaged Area", 
    "Player 2 Current Event": "Player 2 Current Event", 
    "Player 3 Hand": "Player 3 Hand", 
    "Player 3 Deck": "Player 3 Deck", 
    "Player 3 Discard": "Player 3 Discard", 
    "Player 3 Sideboard": "Player 3 Sideboard", 
    "Player 3 Deck 2": "Player 3 Deck 2", 
    "Player 3 Play Area 1": "Player 3 Play Area 1",
    "Player 3 Play Area 2": "Player 3 Play Area 2", 
    "Player 3 Play Area 3": "Player 3 Play Area 3", 
    "Player 3 Play Area 4": "Player 3 Play Area 4", 
    "Player 3 Engaged Area": "Player 3 Engaged Area", 
    "Player 3 Current Event": "Player 3 Current Event", 
    "Player 4 Hand": "Player 4 Hand", 
    "Player 4 Deck": "Player 4 Deck", 
    "Player 4 Discard": "Player 4 Discard", 
    "Player 4 Sideboard": "Player 4 Sideboard", 
    "Player 4 Deck 2": "Player 4 Deck 2", 
    "Player 4 Play Area 1": "Player 4 Play Area 1",
    "Player 4 Play Area 2": "Player 4 Play Area 2", 
    "Player 4 Play Area 3": "Player 4 Play Area 3", 
    "Player 4 Play Area 4": "Player 4 Play Area 4", 
    "Player 4 Engaged Area": "Player 4 Engaged Area", 
    "Player 4 Current Event": "Player 4 Current Event", 
    "Event": "Event",
    "Round": "Round",
    "Staging": "Staging",
    "Progress": "Progress",
    "Get up": "Get up",
    "Look": "Look",
    "Sit down": "Sit down",
    "Taken": "Taken",
    "Shuffle": "Shuffle",
    "Browse": "Browse",
    "Look at top 5": "Look at top 5",
    "Look at top 10": "Look at top 10",
    "Look at top X": "Look at top X",
    "Choose random": "Choose random",
    "Move to": "Move to",
    "More": "More",
    "Deal top X facedown": "Deal top X facedown",
    "Flip": "Flip",
    "Exhaust": "Exhaust",
    "Prevent refresh": "Prevent refresh",
    "Delete": "Delete",
    "Per round": "Per round",
    "Toggle triggers": "Toggle triggers",
    "Top": "Top",
    "Shuffle in": "Shuffle in",
    "Shuffle into top X": "Shuffle into top X",
    "Shuffle into bottom X": "Shuffle into bottom X",
    "Bottom": "Bottom",
    "New Round": "New Round",
    "Peek": "Peek",
    "Swap with top": "Swap with top",
    "Make visible/hidden": "Make visible/hidden",
    "resource": "Resource",
    "progress": "Progress",
    "damage": "Damage",
    "time": "Time",
    "willpower": "Willpower",
    "attack": "Attack",
    "defense": "Defense",
    "threat": "Threat",
    "hitPoints": "Hit Points",
    "Standard": "Standard",
    "Extra staging areas / map": "Extra staging areas / map",
    "Beginning": "Beginning",
    "Resource": "Resource",
    "Set first player": "Set first player",
    "0.0": "Beginning of the round",
    "1.1": "Beginning of the Resource phase",
    "1.R": "1.2 & 1.3: Gain resources and draw cards - <u>then player actions</u>",
    "1.4": "End of the Resource phase",  
    "Planning": "Planning",
    "2.1": "Beginning of the Planning phase",
    "2.P": "2.2 & 2.3: Play cards in turn order - <u>player actions throughout</u>",
    "2.4": "End of the Planning phase",
    "Quest": "Quest",
    "3.1": "Beginning of the Quest phase - <u>then player actions</u>",
    "3.2": "Commit characters to the quest - <u>then player actions</u>",
    "3.3": "Staging - <u>then player actions</u>",
    "3.4": "Quest resolution - <u>then player actions</u>",
    "3.5": "End of the Quest phase",
    "Travel": "Travel",
    "4.1": "Beginning of the Travel phase",
    "4.2": "Travel opportunity - <u>then player actions</u>",
    "4.3": "End of the Travel phase",
    "Encounter": "Encounter",
    "5.1": "Beginning of the Encounter phase",
    "5.2": "Optional engagement - <u>then player actions</u>",
    "5.3": "Engagement checks - <u>then player actions</u>",
    "5.4": "End of the Encounter phase",
    "Combat": "Combat",
    "6.1": "Beginning of the Combat phase",
    "6.2": "Deal shadow cards",
    "6.E": "Enemy attacks - <u>player actions after each combat substep</u>",
    "6.P": "Player attacks - <u>player actions after each combat substep</u>",
    "6.11": "End of the Combat phase",
    "Refresh": "Refresh",
    "7.1": "Beginning of the Refresh phase",
    "7.R": "Ready cards, raise threat, pass P1 token - <u>then player actions</u>",
    "7.5": "End of the Refresh phase",
    "End" : "End",
    "Go Back": "Go back",
    "Detach": "Detach",
    "Swap side": "Swap side",
    "Stop peeking": "Stop peeking",
    "Keep order": "Keep order",
    "Keep peeking": "Keep peeking",
    "Enemy": "Enemy",
    "Location": "Location",
    "Treachery": "Treachery",
    "All": "All",
    "Ally": "Ally",
    "Attachment": "Attachment",
    "Event": "Event",
    "Other": "Other",
    "Easy": "Easy",
    "Normal": "Normal",
    "Nightmare": "Nightmare",
    "None": "None",
    "Top 5": "Top 5",
    "Top 10": "Top 10",
    "Turn faceup...": "Turn faceup...",
    "Close &": "Close &",
  },
  "Spanish": {
    "Menu": "Menú",
    "Player count": "Número de jugadores",
    "Layout": "Formato",
    "Touch mode": "Modo táctil",
    "Touch/mouse mode": "Modo táctil/ratón",
    "Enable": "Habilitar",
    "Disable": "Deshabilitar",
    "Load": "Cargar",
    "Load quest": "Cargar aventura",
    "Load campaign cards": "Cargar cartas de campaña",
    "Load deck (OCTGN file)": "Cargar mazo (archivo de OCTGN)",
    "Load deck (RingsDB URL)": "Cargar mazo (URL de RingsDB)",
    "Load game (.json)": "Cargar partida (.json)",
    "Load custom cards (.txt)": "Cargar cartas no oficiales (.txt)",
    "Unload": "Eliminar",
    "Unload my deck": "Eliminar mi mazo",
    "Unload encounter": "Eliminar mazo de Encuentros",
    "Spawn card": "Generar carta",
    "From the card pool": "Desde el conjunto de cartas",
    "Create your own card": "Crear tu propia carta",
    "Random": "Aleatorio",
    "Coin": "Moneda",
    "Number": "Número",
    "Options": "Opciones",
    "Adjust card size": "Ajustar tamaño de carta",
    "Cards per round": "Cartas por ronda",
    "Battle quest": "Aventura en Batalla",
    "Siege quest": "Aventura en Asedio",
    "Normal quest": "Aventura normal",
    "Special functions": "Funciones especiales",
    "To Catch an Orc Setup": "Preparación de Capturar a un Orco",
    "Escape from Mount Gram Setup": "Preparación de Huida del Monte Gram",
    "The Fortress of Nurn Setup": "Preparación de La Fortaleza de Nurn",
    "Glittering Caves Clues": "Pistas de Las Cavernas Centelleantes",
    "Download": "Descargar",
    "Game state (.json)": "Estado de partida (.json)",
    "Export cards (.txt)": "Exportar cartas (.txt)",
    "Reload decks": "Recargar mazos",
    "Clear table": "Limpiar la mesa",
    "Close room": "Cerrar la sala",
    "Mark as victory": "Marcar como victoria",
    "Mark as defeat": "Marcar como derrota",
    "Mark as incomplete": "Marcar como incompleta",
    "View": "Ver",
    "Hotkeys": "Atajos de teclado",
    "Spectators": "Espectadores",
    "Quest Companion": "Enciclopedia de aventuras",
    "Shared": "Compartido",
    "Player 1": "Jugador 1",
    "Player 2": "Jugador 2",
    "Player 3": "Jugador 3",
    "Player 4": "Jugador 4",
    "player1": "Jugador 1",
    "player2": "Jugador 2",
    "player3": "Jugador 3",
    "player4": "Jugador 4",
    "Encounter Deck": "Mazo de Encuentros", 
    "Encounter Discard": "Descarte de Encuentros", 
    "Discard": "Descarte",
    "Quest Deck": "Mazo de Misión",
    "Quest Discard": "Descarte de Misión",
    "Second Quest Deck": "Segundo Mazo de Misión", 
    "Quest Deck 2": "Mazo de Misión 2",
    "Second Quest Discard": "Descarte del Segundo Mazo de Misión", 
    "Quest Discard 2": "Descarte de Misión 2",
    "Second Encounter Deck": "Segundo Mazo de Encuentros", 
    "Encounter Deck 2": "Mazo de Encuentros 2",
    "Second Encounter Discard": "Descarte del segundo Mazo de Encuentros", 
    "Encounter Discard 2": "Descarte de Encuentros 2",
    "Third Encounter Deck": "Tercer Mazo de Encuentros", 
    "Encounter Deck 3": "Mazo de Encuentros 3",
    "Third Encounter Discard": "Descarte del tercer Mazo de Encuentros", 
    "Encounter Discard 3": "Descarte de Encuentros 3",
    "Other": "Otro", 
    "Set Aside": "Puesto aparte", 
    "Victory Display": "Zona de Victoria", 
    "Staging Area": "Área de Preparación", 
    "Active Location": "Lugar Activo", 
    "Active": "Activo",
    "Main Quest": "Misión Principal", 
    "Extra 1": "Extra 1", 
    "Extra 2": "Extra 2", 
    "Extra 3": "Extra 3", 
    "Hand": "Mano",
    "Deck": "Mazo",
    "Play Area": "Zona de juego",
    "Engaged": "Enfrentamientos",
    "Player 1 Hand": "Jugador 1 Mano", 
    "Player 1 Deck": "Jugador 1 Mazo", 
    "Player 1 Discard": "Jugador 1 Descartes", 
    "Player 1 Sideboard": "Jugador 1 puesto aparte", 
    "Player 1 Deck 2": "Jugador 1 Mazo 2", 
    "Player 1 Play Area 1": "Jugador 1 Zona de juego 1",
    "Player 1 Play Area 2": "Jugador 1 Zona de juego 2", 
    "Player 1 Play Area 3": "Jugador 1 Zona de juego 3", 
    "Player 1 Play Area 4": "Jugador 1 Zona de juego 4", 
    "Player 1 Engaged Area": "Jugador 1 Zona de enfrentamiento", 
    "Player 1 Current Event": "Jugador 1 Evento actual", 
    "Player 2 Hand": "Jugador 2 Mano", 
    "Player 2 Deck": "Jugador 2 Mazo", 
    "Player 2 Discard": "Jugador 2 Descartes", 
    "Player 2 Sideboard": "Jugador 2 puesto aparte", 
    "Player 2 Deck 2": "Jugador 2 Mazo 2", 
    "Player 2 Play Area 1": "Jugador 2 Zona de juego 1",
    "Player 2 Play Area 2": "Jugador 2 Zona de juego 2", 
    "Player 2 Play Area 3": "Jugador 2 Zona de juego 3", 
    "Player 2 Play Area 4": "Jugador 2 Zona de juego 4", 
    "Player 2 Engaged Area": "Jugador 2 Zona de enfrentamiento", 
    "Player 2 Current Event": "Jugador 2 Evento actual", 
    "Player 3 Hand": "Jugador 3 Mano", 
    "Player 3 Deck": "Jugador 3 Mazo", 
    "Player 3 Discard": "Jugador 3 Descartes", 
    "Player 3 Sideboard": "Jugador 3 puesto aparte", 
    "Player 3 Deck 2": "Jugador 3 Mazo 2", 
    "Player 3 Play Area 1": "Jugador 3 Zona de juego 1",
    "Player 3 Play Area 2": "Jugador 3 Zona de juego 2", 
    "Player 3 Play Area 3": "Jugador 3 Zona de juego 3", 
    "Player 3 Play Area 4": "Jugador 3 Zona de juego 4", 
    "Player 3 Engaged Area": "Jugador 3 Zona de enfrentamiento", 
    "Player 3 Current Event": "Jugador 3 Evento actual", 
    "Player 4 Hand": "Jugador 4 Mano", 
    "Player 4 Deck": "Jugador 4 Mazo", 
    "Player 4 Discard": "Jugador 4 Descartes", 
    "Player 4 Sideboard": "Jugador 4 puesto aparte", 
    "Player 4 Deck 2": "Jugador 4 Mazo 2", 
    "Player 4 Play Area 1": "Jugador 4 Zona de juego 1",
    "Player 4 Play Area 2": "Jugador 4 Zona de juego 2", 
    "Player 4 Play Area 3": "Jugador 4 Zona de juego 3", 
    "Player 4 Play Area 4": "Jugador 4 Zona de juego 4", 
    "Player 4 Engaged Area": "Jugador 4 Zona de enfrentamiento", 
    "Player 4 Current Event": "Jugador 4 Evento actual", 
    "Event": "Evento",
    "Round": "Ronda",
    "Staging": "Preparación",
    "Progress": "Progreso",
    "Get up": "Levantarse",
    "Look": "Mirar",
    "Sit down": "Sentarse",
    "Taken": "Tomado",
    "Shuffle": "Barajar",
    "Browse": "Buscar",
    "Look at top 5": "Mirar las 5 superiores",
    "Look at top 10": "Mirar las 10 superiores",
    "Look at top X": "Mirar las X superiores",
    "Choose random": "Elegir aleatoriamente",
    "Move to": "Mover a",
    "More": "Más",
    "Deal top X facedown": "Repartir las X superiores boca abajo",
    "Flip": "Voltear",
    "Exhaust": "Agotar",
    "Prevent refresh": "Evitar recuperación",
    "Delete": "Borrar",
    "Per round": "Por ronda",
    "Toggle triggers": "Activadores",
    "Top": "Superior",
    "Shuffle in": "Barajar en",
    "Shuffle into top X": "Barajar X superiores en",
    "Shuffle into bottom X": "Barajar X inferiores en",
    "Bottom": "Inferior",
    "New Round": "Nueva ronda",
    "Peek": "Ojear",
    "Swap with top": "Intercambiar con la superior",
    "Make visible/hidden": "Hacer visible/ocultar",
    "resource": "Recurso",
    "progress": "Progreso",
    "damage": "Daño",
    "time": "Tiempo",
    "willpower": "Fuerza de Voluntad",
    "attack": "Ataque",
    "defense": "Defensa",
    "threat": "Amenaza",
    "hitPoints": "Puntos de Impacto",
    "Standard": "Estándar",
    "Extra staging areas / map": "Áreas de preparación extra / mapa",
    "Beginning": "Comienzo",
    "Resource": "Recursos",
    "Set first player": "Establecer jugador inicial",
    "0.0": "Comienzo de la ronda",
    "1.1": "Comienzo de la Fase de Recursos",
    "1.R": "1.2 & 1.3: Obtener recursos y robar cartas - <u>ventana de acción</u>",
    "1.4": "Fin de la Fase de Recursos",  
    "Planning": "Planificación",
    "2.1": "Comienzo de la Fase de Planificación",
    "2.P": "2.2 & 2.3: Jugar cartas en orden de turno - <u>ventana de acción</u>",
    "2.4": "Fin de la Fase de Planificación",
    "Quest": "Misión",
    "3.1": "Comienzo de la Fase de Misión - <u>ventana de acción</u>",
    "3.2": "Asignar personajes a la misión - <u>ventana de acción</u>",
    "3.3": "Preparación - <u>ventana de acción</u>",
    "3.4": "Resolución de la misión - <u>ventana de acción</u>",
    "3.5": "Fin de la Fase de Misión",
    "Travel": "Viaje",
    "4.1": "Comienzo de la Fase de Viaje",
    "4.2": "Opción de viaje - <u>ventana de acción</u>",
    "4.3": "Fin de la Fase de Viaje",
    "Encounter": "Encuentros",
    "5.1": "Comienzo de la Fase de Encuentros",
    "5.2": "Enfrentamientos opcionales - <u>ventana de acción</u>",
    "5.3": "Comprobaciones de enfrentamiento - <u>ventana de acción</u>",
    "5.4": "Fin de la Fase de Encuentros",
    "Combat": "Combate",
    "6.1": "Comienzo de la Fase de Combate",
    "6.2": "Repartir cartas de Sombra",
    "6.E": "Resolver ataques enemigos - <u>ventana de acción tras cada paso de combate</u>",
    "6.P": "Ataques del jugador - <u>ventana de acción tras cada paso de combate</u>",
    "6.11": "Fin de la Fase de Combate",
    "Refresh": "Recuperación",
    "7.1": "Comienzo de la Fase de Recuperación",
    "7.R": "Preparar cartas, aumentar amenaza, pasar la ficha de jugador inicial - <u>ventana de acción</u>",
    "7.5": "Fin de la Fase de Recuperación",
    "End": "Fin",
    "Go Back": "Atrás",
    "Detach": "Desvincular",
    "Swap side": "Cambiar de lado",
    "Stop peeking": "Deja de mirar",
    "Keep order": "Mantener el orden",
    "Keep peeking": "Seguir mirando",
    "Enemy": "Enemigo",
    "Location": "Lugar",
    "Treachery": "Traición",
    "All": "Todas",
    "Ally": "Aliado",
    "Attachment": "Vinculada",
    "Event": "Evento",
    "Other": "Otra",
    "Easy": "Fácil",
    "Normal": "Normal",
    "Nightmare": "Pesadilla",
    "None": "Ninguna",
    "Top 5": "5 superiores",
    "Top 10": "10 superiores",
    "Turn faceup...": "Poner boca arriba...",
    "Close &": "Cerrar &",
    "Display all tokens": "Mostrar todas las fichas",
    "Remove all tokens": "Retirar todas las fichas",
    "Exhaust / ready. If location, make active.": "Agotar / preparar. Si es un Lugar, lo convierte en Lugar Activo",
    "Detach": "Desvincular",
    "Flip": "Voltear",
    "Shuffle into owner's deck": "Barajar en el mazo de su propietario",
    "Commit / uncommit from quest": "Asignar / retirar de la misión",
    "Commit / uncommit from quest without exhausting / readying": "Asignar / retirar de la misión sin agotar / preparar",
    "Deal shadow card": "Repartir carta de Sombra",
    "Target card": "Marcar carta",
    "Add to victory display": "Añadir a la zona de victoria",
    "Start/stop drawing arrow": "Dibujar/borrar flecha hacia la carta",
    "Discard": "Descartar",
    "Discard all other cards in this card's stack": "Descartar el resto de cartas de la pila de esta carta",
    "Send attachment to back of stack": "Mostrar Vinculada detrás",
    "Trigger ability (only available for select cards).": "Disparar habilidad (sólo disponible para cartas seleccionadas)",
    "Draw card": "Robar carta",
    "Reveal encounter card": "Revelar carta de Encuentro",
    "Deal facedown encounter card": "Repartir carta de Encuentro boca abajo",
    "Reveal encounter card from second encounter deck": "Revelar carta de Encuentro del segundo mazo de Encuentros",
    "Deal facedown encounter card from second encounter deck": "Repartir carta de Encuentro boca abajo del segundo mazo de Encuentros",
    "Mulligan": "Hacer Mulligan",
    "Draw card and gain resources. If host, increment round.": "Robar carta y obtener recursos. Si es el anfitrión, avanza ronda",
    "Calculate score": "Calcular puntuación",
    "Refresh and raise threat. If host, move 1st player token.": "Recuperar y aumentar amenaza. Si es el anfritrión, pasa la ficha de jugador inicial",
    "Deal all shadow cards": "Repartir todas las cartas de Sombra",
    "Discard all shadow cards": "Descartar todas las cartas de Sombra",
    "Raise threat by 1": "Incrementar tu amenaza en 1",
    "Reduce threat by 1": "Reducir tu amenaza en 1",
    "Save Game": "Guardar partida",
    "Clear targets/arrows": "Desmarcar cartas/borrar flechas",
    "Undo one action": "Deshacer una acción",
    "Redo one action": "Rehacer una acción",
    "Undo until last round change": "Deshacer hasta el último cambio de ronda",
    "Redo until next round change": "Rehacer hasta el próximo cambio de ronda",
    "Move to previous game step": "Ir a la etapa anterior de la partida",
    "Move to next game step": "Ir a la etapa siguiente de la partida",
    "Move to previous phase": "Ir a la fase anterior",
    "Move to next phase": "Ir a la fase siguiente",
    "Increase card size (for your screen only)": "Incrementar el tamaño de carta (sólo para tu pantalla)",
    "Decrease card size (for your screen only)": "Reducir el tamaño de carta (sólo para tu pantalla)",
    "If host, enable multiplayer hotkeys.": "Si es el anfitrión, habilitar atajos de teclado multijugador",
    "Press Shift+N for all players.": "Presionar Shift+N para todos los jugadores",
    "Press Shift+R for all players.": "Presionar Shift+R para todos los jugadores",
    "Raise threat by 1 for all players.": "Incrementar la amenaza en 1 para todos los jugadores",
    "Reduce threat by 1 for all players.": "Reducir la amenaza en 1 para todos los jugadores",
    "Sit in next open seat.": "Sentarse en el siguiente puesto disponible",
    "Draw card on behalf of next empty seat.": "Robar carta en nombre del siguiente puesto vacío",
    "Tokens": "Fichas",
    "Hover over the top/bottom half of the card when pressing the key to add/remove tokens.": "Mantener el cursor sobre la mitad superior/inferior de la tarjeta al presionar la tecla para añadir/retirar fichas",
    "Card hotkeys": "Atajos de carta",
    "Hover over a card.": "Mantener el cursor sobre una carta",
    "Multiplayer hotkeys": "Atajos en multijugador",
    "Useful for two-handed play.": "Útil para juego a dos manos",
    "Game hotkeys": "Atajos de partida",
    "Tokens": "Fichas",
    "Hover over the top/bottom half of the card when pressing the key to add/remove tokens.": "Mantener el cursor sobre la mitad superior/inferior de la carta al presionar la tecla para añadir/retirar fichas",
    "Card hotkeys": "Atajos de carta",
    "Hover over a card.": "Mantener el cursor sobre una carta",
    "Game hotkeys": "Atajos de partida",
    "Multiplayer hotkeys": "Atajos en multijugador",
    "Useful for two-handed play.": "Útil para juego a dos manos",

    //Encounter Deck window

    "Encounter Deck": "Mazo de Encuentros",
    "Discard until Enemy": "Descartar hasta Enemigo",
    "Discard until Location": "Descartar hasta Lugar",
    "Choose Random": "Elegir aleatoriamente",
    "Go Back": "Atrás",
    "My Deck": "Mi mazo",

    //Encounter Discard window

    "Encounter Discard": "Descarte de Encuentros",
    "Shuffle into deck": "Barajar en el mazo",
    "Choose Random": "Elegir aleatoriamente",
    "Go Back": "Atrás",
    "My Deck": "Mi mazo",

    //Set Aside window

    "Choose Random": "Elegir aleatoriamente",
    "Go Back": "Atrás",
    "My Deck": "Mi mazo",

    //Player card window

    "Player card": "Carta de jugador",
    "Owner's deck": "Mazo de su propietario",

    //Player hand window

    "Player 1 hand": "Mano del jugador 1",
    "Player 2 hand": "Mano del jugador 2",
    "Player 3 hand": "Mano del jugador 3",
    "Player 4 hand": "Mano del jugador 4",
    "Choose Random": "Elegir aleatoriamente",
    "Go Back": "Atrás",
    "My Deck": "Mi mazo",

    //Load quest window

    "Go Back": "Atrás",
    "Core Set & Shadows of Mirkwood": "Caja Básica y Sombras del Bosque Negro",
    "Khazad-dûm & Dwarrowdelf": "Khazad-dûm y La Mina del Enano",
    "Heirs of Númenor & Against the Shadow": "Herederos de Númenor y Contra la Sombra",
    "The Voice of Isengard & The Ringmaker": "La Voz de Isengard y El Creador del Anillo",
    "The Lost Realm & Angmar Awakened": "El Reino Perdido y Angmar Despertado",
    "The Grey Havens & The Dreamchaser": "Los Puertos Grises y Cazador de Sueños",
    "The Sands of Harad & The Haradrim": "Las Arenas de Harad y Los Haradrim",
    "The Wilds of Rhovanion & Ered Mithrin": "Las Tierras Salvajes de Rhovanion y Ered Mithrin",
    "A Shadow in the East & Vengenace of Mordor": "Una Sombra en el Este y La Venganza de Mordor",
    "The Hobbit": "El Hobbit",
    "The Lord of the Rings Standalone": "El Señor de los Anillos Autojugable",
    "The Lord of the Rings Campaign": "El Señor de los Anillos Campaña",
    "Two-Player Limited-Edition Starter": "Las Penumbras del Bosque Negro",
    "Print on Demand": "Escenarios autojugables",
    "ALeP - Children of Eorl & Oaths of the Rohirrim": "ALeP - Descendientes de Eorl y Juramentos de los Rohirrim",
    "Passage Through Mirkwood": "A Través del Bosque Negro",
    "Journey Along the Anduin": "Travesía por el Anduin",
    "Escape from Dol Guldur": "Evasión de Dol Guldur",
    "The Hunt for Gollum": "La Caza de Gollum",
    "Conflict at the Carrock": "Conflicto en la Carroca",
    "A Journey to Rhosgobel": "Viaje a Rhosgobel",
    "The Hills of Emyn Muil": "Las Colinas de Emyn Muil",
    "The Dead Marshes": "Las Ciénagas de los Muertos",
    "Return to Mirkwood": "Regreso al Bosque Negro",
    "Into the Pit": "En el Pozo",
    "The Seventh Level": "El Séptimo Nivel",
    "Flight from Moria": "Huida de Moria",
    "The Redhorn Gate": "La Puerta del Cuerno Rojo",
    "Road to Rivendell": "Camino a Rivendel",
    "The Watcher in the Water": "El Guardián del Agua",
    "The Long Dark": "La Larga Oscuridad",
    "Foundations of Stone": "Cimientos de Piedra",
    "Shadow and Flame": "Sombra y Llama",
    "Peril In Pelargir": "Peligro en Pelargir",
    "Into Ithilien": "A Ithilien",
    "The Siege of Cair Andros": "El Asedio de Cair Andros",
    "The Steward's Fear": "El Temor del Senescal",
    "The Drúadan Forest": "El Bosque de Drúadan",
    "Encounter at Amon Dîn": "Encuentro en Amon Dîn",
    "Assault on Osgiliath": "Asalto a Osgiliath",
    "The Blood of Gondor": "La Sangre de Gondor",
    "The Morgul Vale": "El Valle de Morgul",
    "Fords of Isen": "Los Vados del Isen",
    "To Catch an Orc": "Capturar a un Orco",
    "Into Fangorn": "A Fangorn",
    "The Dunland Trap": "La Trampa de las Tierras Brunas",
    "The Three Trials": "Las Tres Pruebas",
    "Trouble in Tharbad": "Problemas en Tharbad",
    "The Nin in Eilph": "Nîn-in-Eilph",
    "Celebrimbor's Secret": "El Secreto de Celebrimbor",
    "The Antlered Crown": "La Corona Astada",
    "Intruders in Chetwood": "Intrusos en el Bosque de Chet",
    "The Weather Hills": "Las Colinas de los Vientos",
    "Deadmen's Dike": "Muros de los Muertos",
    "The Wastes of Eriador": "Los Páramos de Eriador",
    "Escape from Mount Gram": "Huida del Monte Gram",
    "Across the Ettenmoors": "A Través de las Landas de Etten",
    "The Treachery of Rhudaur": "La Traición de Rhudaur",
    "The Battle of Carn Dum": "La Batalla de Carn Dûm",
    "The Dread Realm": "El Reino del Terror",
    "Voyage Across Belegaer": "Travesía por Belegaer",
    "The Fate of Numenor": "El Destino de Númenor",
    "Raid on the Grey Havens": "Incursión en los Puertos Grises",
    "Flight of the Stormcaller": "La Huida del Tormentera",
    "The Thing in the Depths": "La Cosa de las Profundidades",
    "Temple of the Deceived": "El Templo de los Engañados",
    "The Drowned Ruins": "Las Ruinas Anegadas",
    "A Storm on Cobas Haven": "Tormenta en la Bahía Cobas",
    "The City of Corsairs": "La Ciudad de los Corsarios",
    "Escape from Umbar": "Huida de Umbar",
    "Desert Crossing": "Travesía por el Desierto",
    "The Long Arm of Mordor": "El Largo Brazo de Mordor",
    "The Mumakil": "Los Mûmakil",
    "Race Across Harad": "Carrera por Harad",
    "Beneath the Sands": "Bajo las Arenas",
    "The Black Serpent": "La Serpiente Negra",
    "The Dungeons of Cirith Gurat": "Las Mazmorras de Cirith Gurat",
    "The Crossings of Poros": "Los Cruces del Poros",
    "Journey Up the Anduin": "Remontando el Anduin",
    "Lost in Mirkwood": "Perdidos en el Bosque Negro",
    "The King's Quest": "La Misión del Rey",
    "The Withered Heath": "El Brezal Marchito",
    "Roam Across Rhovanion": "Recorrido por Rhovanion",
    "Fire in the Night": "Fuego en la Noche",
    "The Ghost of Framsburg": "El Fantasma de Framsburgo",
    "Mount Gundabad": "El Monte Gundabad",
    "The Fate of Wilderland": "El Destino de las Tierras Ásperas",
    "The River Running": "El Río Rápido",
    "Danger in Dorwinian": "Peligro en Dorwinion",
    "Temple of Doom": "El Templo Maldito",
    "Wrath and Ruin": "Ira y Perdición",
    "The City of Ulfast": "La Ciudad de Ulfast",
    "Challenge of the Wainriders": "El Desafío de los Aurigas",
    "Under the Ash Mountains": "Bajo las Montañas de Ceniza",
    "The Land of Sorrow": "La Tierra del Pesar",
    "The Fortress of Nurn": "La Fortaleza de Nurn",
    "We Must Away Ere Break of Day": "Hemos de ir, antes de que el día nazca",
    "Over the Misty Mountains Grim": "Más allá de las hoscas y brumosas montañas",
    "Dungeons Deep and Caverns Dim": "A cavernas profundas y mazmorras antiguas",
    "Flies and Spiders": "Moscas y Arañas",
    "The Lonely Mountain": "La Montaña Solitaria",
    "The Battle of Five Armies": "La Batalla de los Cinco Ejércitos",
    "A Shadow of the Past": "Una Sombra del Pasado",
    "The Old Forest": "El Bosque Viejo",
    "Fog on the Barrow downs": "Niebla en las Quebradas de los Túmulos",
    "A Knife in the Dark": "Un Cuchillo en la Oscuridad",
    "Flight to the Ford": "Huyendo hacia el Vado",
    "The Ring Goes South": "El Anillo va hacia el Sur",
    "Journey in the Dark": "Viaje a la Oscuridad",
    "Breaking of the Fellowship": "La Disolución de la Comunidad",
    "The Uruk hai": "Los Uruk-hai",
    "Helm's Deep": "El Abismo de Helm",
    "The Road to Isengard": "El Camino de Isengard",
    "The Passage of the Marshes": "A Través de las Ciénagas",
    "Journey to the Cross roads": "Viaje a la Encrucijada",
    "Shelob's Lair": "El antro de Ella-Laraña",
    "The Passing of the Grey Company": "El Paso de la Compañía Gris",
    "The Siege of Gondor": "El Sitio de Gondor",
    "The Battle of the Pelennor Fields": "La Batalla de los Campos del Pelennor",
    "The Tower of Cirith Ungol": "La Torre de Cirith Ungol",
    "The Black Gate Opens": "La Puerta Negra se abre",
    "Mount Doom": "El Monte del Destino",
    "ALeP The Scouring of the Shire": "ALEP El Saneamiento de la Comarca",
    "The Oath": "La Promesa",
    "The Caves of Nibin Dum": "Las Cuevas de Nibin-dûm",
    "The Massing at Osgiliath": "Concentración en Osgiliath",
    "The Battle of Lake Town": "La Batalla de la Ciudad del Lago",
    "The Stone of Erech": "La Piedra de Erech",
    "The Old Forest": "El Bosque Viejo",
    "Fog on the Barrow downs": "Niebla en las Quebradas de los Túmulos",
    "The Ruins of Belegost": "Las Ruinas de Belegost",
    "Murder at the Prancing Pony": "Asesinato en el Poney Pisador",
    "Standard The Siege of Annuminas": "El Sitio de Annúminas Estándar",
    "Epic The Siege of Annuminas": "El Sitio de Annúminas Épico",
    "Attack on Dol Guldur": "Ataque a Dol Guldur",
    "The Wizard's Quest": "La Misión del Mago",
    "The Woodland Realm": "El Reino del Bosque",
    "The Mines of Moria": "Las Minas de Moria",
    "Escape from Khazad dûm": "Huida de Khazad-dûm",
    "The Hunt for the Dreadnaught": "La Caza del Dreadnaught",
    "ALeP The Scouring of the Shire": "ALeP El Saneamiento de la Comarca",
    "ALeP Ambush at Erelas": "ALeP Emboscada en Erelas",
    "ALeP The Battle for the Beacon": "ALeP La Batalla por la Almenara",
    "ALeP The Horse Lord s Ire": "ALeP La Ira del Señor de los Caballos",
    "ALeP The Aldburg Plot": "ALeP La Conspiración de Aldburg",
    "ALeP Fire on the Eastemnet": "ALeP Fuego en el Eastemnet",
    "ALeP The Gap of Rohan": "ALeP El Paso de Rohan",
    "ALeP The Glittering Caves": "ALeP Las Cavernas Centelleantes",

  },
  "French": {
    "Menu": "Menu",
    "Player count": "Nombre de joueur",
    "Layout": "Mise en page",
    "Touch mode": "Mode tablette",
    "Touch/mouse mode": "Mode tablette/souris",
    "Enable": "Activer",
    "Disable": "Désactiver",
    "Load": "Chargement",
    "Load quest": "Charger quête",
    "Load campaign cards": "Charger cartes de campagne",
    "Load deck (OCTGN file)": "Télécharger deck (Fichier OCTGN)",
    "Load deck (RingsDB URL)": "Télécharger deck (Adresse URL ringsdb)",
    "Load game (.json)": "Télécharger parties(.json)",
    "Load custom cards (.txt)": "Télécharger cartes personnalisées (.txt)",
    "Unload": "Désinstaller",
    "Unload my deck": "Désinstaller mon deck",
    "Unload encounter": "Désinstaller deck de rencontre",
    "Spawn card": "Rechercher une carte",
    "From the card pool": "Dans l'ensemble des cartes",
    "Create your own card": "Créer sa propre carte",
    "Random": "Tirage au sort",
    "Coin": "Pile ou Face",
    "Number": "Nombre aléatoire",
    "Options": "Options",
    "Adjust card size": "Ajuster la taille des cartes",
    "Cards per round": "Nombre de cartes par tour",
    "Battle quest": "Mode Bataille",
    "Siege quest": "Mode Siège",
    "Normal quest": "Mode Normal",
    "Special functions": "Mise en place particulière",
    "To Catch an Orc Setup": "Préparation : Chasse à l'orque",
    "Escape from Mount Gram Setup": "Préparation : Fuite du Mont Gram",
    "The Fortress of Nurn Setup": "Préparation : La Forteresse de Nurn",
    "Glittering Caves Clues": "Indices des Cavernes Scintillantes",
    "Download": "Télécharger",
    "Game state (.json)": "État d'avancement de la partie (.json)",
    "Export cards (.txt)": "Exporter cartes personnalisées (.txt)",
    "Reload decks": "Recharger decks",
    "Clear table": "Vider la table",
    "Close room": "Fermer la table",
    "Mark as victory": "Valider comme une victoire",
    "Mark as defeat": "Valider comme une défaite",
    "Mark as incomplete": "Valider comme inachevée",
    "View": "Vue",
    "Hotkeys": "Raccourcis",
    "Spectators": "Spectateurs",
    "Quest Companion": "Répertoire des quêtes (en anglais)",
    "Shared": "Partager",
    "Player 1": "Joueur 1",
    "Player 2": "Joueur 2",
    "Player 3": "Joueur 3",
    "Player 4": "Joueur 4",
    "player1": "Joueur 1",
    "player2": "Joueur 2",
    "player3": "Joueur 3",
    "player4": "Joueur 4",
    "Encounter Deck": "Deck de rencontre", 
    "Encounter Discard": "Pile de défausse du deck de rencontre", 
    "Discard": "Défausse",
    "Quest Deck": "Deck de quête",
    "Quest Discard": "Pile de défausse du deck de quête",
	  "Second Quest Deck": "Deuxième deck de quête", 
    "Quest Deck 2": "Deck de quête 2",
    "Second Quest Discard": "Pile de défausse du 2ème deck de quête",
    "Quest Discard 2": "Pile de défausse du deck de quête 2",
    "Second Encounter Deck": "Deuxième deck de rencontre", 
    "Encounter Deck 2": "Deck de rencontre 2",
    "Second Encounter Discard": "Pile de défausse du 2ème deck de rencontre", 
    "Encounter Discard 2": "Pile de défausse du deck de rencontre 2",
    "Third Encounter Deck": "Troisième deck de rencontre", 
    "Encounter Deck 3": "Deck de rencontre 3",
    "Third Encounter Discard": "Pile de défausse du 3ème deck de rencontre", 
    "Encounter Discard 3": "Pile de défausse du deck de rencontre 3",
    "Other": "Autre", 
    "Set Aside": "Mis de côté", 
    "Victory Display": "Pile de victoire", 
    "Staging Area": "Zone de cheminement", 
    "Active Location": "Lieu actif", 
    "Active": "Lieu actif",
    "Main Quest": "Quête principale", 
    "Extra 1": "Extra 1", 
    "Extra 2": "Extra 2", 
    "Extra 3": "Extra 3", 
    "Hand": "Main",
	  "Deck": "Deck",
    "Play Area": "Zone de jeu",
    "Engaged": "Engagement",
    "Player 1 Hand": "Main Joueur 1", 
    "Player 1 Deck": "Deck Joueur 1", 
    "Player 1 Discard": "Défausse Joueur 1", 
	  "Player 1 Sideboard": " Sideboard Joueur 1", 
    "Player 1 Deck 2": "Joueur 1 Deck 2", 
    "Player 1 Play Area 1": "Zone de jeu 1 Joueur 1",
    "Player 1 Play Area 2": "Zone de jeu 2 Joueur 1", 
    "Player 1 Play Area 3": "Zone de jeu 3 Joueur 1",
    "Player 1 Play Area 4": "Zone de jeu 4 Joueur 1",
    "Player 1 Engaged Area": "Zone d'Engagement Joueur 1",
    "Player 1 Current Event": "Événement en cours Joueur 1",
	  "Player 2 Hand": "Main Joueur 2", 
    "Player 2 Deck": "Deck Joueur 2", 
    "Player 2 Discard": "Défausse Joueur 2", 
	  "Player 2 Sideboard": " Sideboard Joueur 2", 
    "Player 2 Deck 2": "Joueur 2 Deck 2", 
    "Player 2 Play Area 1": "Zone de jeu 1 Joueur 2",
    "Player 2 Play Area 2": "Zone de jeu 2 Joueur 2", 
    "Player 2 Play Area 3": "Zone de jeu 3 Joueur 2",
    "Player 2 Play Area 4": "Zone de jeu 4 Joueur 2",
    "Player 2 Engaged Area": "Zone d'Engagement Joueur 2",
    "Player 2 Current Event": "Événement en cours Joueur 2",
	  "Player 3 Hand": "Main Joueur 3", 
    "Player 3 Deck": "Deck Joueur 3", 
    "Player 3 Discard": "Défausse Joueur 3", 
	  "Player 3 Sideboard": " Sideboard Joueur 3", 
    "Player 3 Deck 2": "Joueur 3 Deck 2", 
    "Player 3 Play Area 1": "Zone de jeu 1 Joueur 3",
    "Player 3 Play Area 2": "Zone de jeu 2 Joueur 3", 
    "Player 3 Play Area 3": "Zone de jeu 3 Joueur 3",
    "Player 3 Play Area 4": "Zone de jeu 4 Joueur 3",
    "Player 3 Engaged Area": "Zone d'Engagement Joueur 3",
    "Player 3 Current Event": "Événement en cours Joueur 3",
	  "Player 4 Hand": "Main Joueur 4", 
    "Player 4 Deck": "Deck Joueur 4", 
    "Player 4 Discard": "Défausse Joueur 4", 
	  "Player 4 Sideboard": " Sideboard Joueur 4", 
    "Player 4 Deck 2": "Joueur 1 Deck 2", 
    "Player 4 Play Area 1": "Zone de jeu 1 Joueur 4",
    "Player 4 Play Area 2": "Zone de jeu 2 Joueur 4", 
    "Player 4 Play Area 3": "Zone de jeu 3 Joueur 4",
    "Player 4 Play Area 4": "Zone de jeu 4 Joueur 4",
    "Player 4 Engaged Area": "Zone d'Engagement Joueur 4",
    "Player 4 Current Event": "Événement en cours Joueur 4",
    "Event": "Événement",
    "Round": "Tour",
    "Staging": "Menace",
    "Progress": "Progression",
    "Get up": "Se lever",
    "Look": "Observer",
    "Sit": "S'asseoir",
	  "Taken": "Occupé",
    "Shuffle": "Mélanger",	
    "Browse": "Consulter",
    "Look at top 5": "Regarder les 5 premières cartes",
    "Look at top 10": "Regarder les 10 premières cartes",
    "Look at top X": "Regarder les X premières cartes",
    "Choose random": "Choisir aléatoirement",
    "Move to": "Déplacer vers",
    "More": "Plus",
    "Deal top X facedown": "Placer X du sommet du deck face cachée",
    "Flip": "Retourner",
    "Exhaust": "Incliner",
    "Prevent refresh": "Ne se redresse pas à la phase de restauration ",
    "Delete": "Effacer",
    "Per round": "Par tour",
    "Toggle triggers": "Déclencher l'effet",
    "Top": "Au sommet ",
    "Shuffle in": "Mélanger",
    "Shuffle into top X": "Mélanger dans les X premières cartes",
    "Shuffle into bottom X": "Mélanger dans les X dernières cartes",
    "Bottom": "En-dessous",
    "New Round": "Nouveau tour",
    "Peek": "Examiner",
    "Swap with top": "Échanger cette carte avec la carte du dessus de votre jeu.",
    "Make visible/hidden": "Rendre visible/caché",
    "resource": "Ressource",
    "progress": "Progression",
    "damage": "Dégâts",
    "time": "Temps",
    "willpower": "Volonté",
    "attack": "Attaque",
    "defense": "Défense",
    "threat": "Menace",
    "hitPoints": "Points de vie",
    "Standard": "Standard",
    "Extra staging areas / map": "Zones de cheminement supplémentaires / plan",
    "Beginning": "Début",
    "Resource": "Ressource",
    "Set first player": "Déterminer le¨Premier Joueur",
    "0.0": "Début du tour",
    "1.1": "Début de la phase de Ressource",
    "1.R": "1.2 & 1.3: Gain de ressources and pioche de cartes - <u>puis actions des joueurs</u>",
    "1.4": "Fin de la phase de Ressource",  
    "Planning": "Organisation",
    "2.1": "Début de la phase d'Organisation",
    "2.P": "2.2 & 2.3: Mise en jeu de cartes selon le tour de jeu - <u>actions des joueurs à tout moment</u>",
    "2.4": "Fin de la phase d'Organisation",
    "Quest": "Quête",
    "3.1": "Début de la phase de Quête - <u>puis actions des joueurs</u>",
    "3.2": "Engagement de personnages dans la quête - <u>puis actions des joueurs</u>",
    "3.3": "Étape de renforcement - <u>puis actions des joueurs</u>",
    "3.4": "Résolution de la quête - <u>puis actions des joueurs</u>",
    "3.5": "Fin de la phase de Quête",
    "Travel": "Voyage", 
    "4.1": "Début de la phase de Voyage",
    "4.2": "Opportunités de voyage - <u>puis actions des joueurs</u>",
    "4.3": "Fin de la phase de Voyage",
    "Encounter": "Rencontre",
    "5.1": "Début de la phase de Rencontre",
    "5.2": "Engagement intentionnel - <u>puis actions des joueurs</u>",
    "5.3": "Test d'engagement - <u>puis actions des joueurs</u>",
    "5.4": "Fin de la phase de Rencontre",
    "Combat": "Combat",
    "6.1": "Début de la phase de Combat",
    "6.2": "Attribution des cartes ombre",
    "6.E": "Attaques ennemies - <u>action des joueurs après chaque étape du combat</u>",
    "6.P": "Attaque des joueurs - <u>action des joueurs après chaque étape du combat</u>",
    "6.11": "Fin de la phase de Combat",
    "Refresh": "Restauration",
    "7.1": "Début de la phase de Restauration",
    "7.R": "Redressement des cartes, augmentation de la menace de 1, transfert du jeton du premier joueur - <u>puis actions des joueurs</u>",
    "7.5": "Fin de la phase de Restauration",
    "End" : "Fin",
    "Go Back": "Retour arrière",
    "Detach": "Détacher",
    "Swap side": "Côté opposé",
    "Stop peeking": "Arrêter de regarder",
    "Keep order": "Conserver l'ordre",
    "Keep peeking": "Continuer de regarder",
    "Enemy": "Ennemi",
    "Location": "Lieu",
    "Treachery": "Traîtrise",
    "All": "Tout",
    "Ally": "Allié",
    "Attachment": "Attachement",
    "Event": "Événement",
    "Other": "Autre",
    "Easy": "Facile",
    "Normal": "Normal",
    "Nightmare": "Cauchemar",
    "None": "Aucun",
    "Top 5": "Les 5 premier(e)s",
    "Top 10": "Les 10 premier(e)s",
    "Turn faceup...": "Réveler...",
    "Close &": "Fermer &",
    "Display all tokens": "Afficher tous les marqueurs",
    "Remove all tokens": "Retirer tous les marqueurs",
    "Exhaust / ready. If location, make active.": " Incliner / redresser. Rendre un lieu actif",
    "Detach": "Détacher",
    "Flip": "Retourner",
    "Shuffle into owner's deck": "Mélanger dans le deck du contrôleur",
    "Commit / uncommit from quest": "S'engager / se désengager de la quête",
    "Commit / uncommit from quest without exhausting / readying": "S'engager / se désengager de la quête sans s'incliner / se redresser",
    "Deal shadow card": "Attribuer une carte ombre",
    "Target card": "Cibler une carte",
    "Add to victory display": "Ajouter à la pile de victoire",
    "Start/stop drawing arrow": "Dessiner une flèche",
    "Discard": "Défausser",
    "Discard all other cards in this card's stack": "Défausser toutes les autres cartes attachées",
    "Send attachment to back of stack": "Envoyer l'attachement à la suite de la pile",
    "Trigger ability (only available for select cards).": "Déclencher un trait (disponible que pour certaines cartes).",
    "Draw card": "Piocher une carte",
    "Reveal encounter card": "Révéler une carte du deck de rencontre",
    "Deal facedown encounter card": "Placer face cachée une carte du deck de rencontre",
    "Reveal encounter card from second encounter deck": "Révéler une carte du 2ème deck de rencontre",
    "Deal facedown encounter card from second encounter deck": "Placer face cachée une carte du 2ème deck de rencontre",
    "Mulligan": "Faire un Mulligan",
    "Draw card and gain resources. If host, increment round.": "Piocher une carte, ajouter les marqueurs de ressource. Si hôte de la partie, incrémenter le compteur de round",
    "Calculate score": "Calculer le décompte",
    "Refresh and raise threat. If host, move 1st player token.": "Phase de restauration. Augmenter le niveau de menace. Passer le marqueur premier joueur",
    "Deal all shadow cards": "Attribuer toutes les cartes ombre",
    "Discard all shadow cards": "Défausser toutes les cartes ombre",
    "Raise threat by 1": "Augmenter le niveau de menace de 1",
    "Reduce threat by 1": "Diminuer le niveau de menace",
    "Save Game": "Sauvegarder la partie",
    "Clear targets/arrows": "Supprimer les cibles/fléches",
    "Undo one action": "Annuler une action",
    "Redo one action": "Rétablir une action",
    "Undo until last round change": "Annuler jusqu'au tour précédent",
    "Redo until next round change": "Rétablir jusqu'au tour suivant",
    "Move to previous game step": "Avancer jusqu'à la précédente étape de jeu",
    "Move to next game step": "Avancer jusqu'à la prochaine étape de jeu",
    "Move to previous phase": "Avancer jusqu'à la précédente phase de jeu",
    "Move to next phase": "Avancer jusqu'à la prochaine phase de jeu",
    "Increase card size (for your screen only)": "Augmenter la taille des cartes (seulement pour votre écran)",
    "Decrease card size (for your screen only)": "Réduire la taille des cartes (seulement pour votre écran)",
    "If host, enable multiplayer hotkeys.": "Si hôte de la partie, activer les touches de raccourci multijoueur ",
    "Press Shift+N for all players.": "Déclencher Shift+N pour tous les joueurs",
    "Press Shift+R for all players.": "Déclencher Shift+R pour tous les joueurs",
    "Raise threat by 1 for all players.": "Augmenter le niveau de menace de 1 pour tous les joueurs",
    "Reduce threat by 1 for all players.": "Réduire le niveau de menace de 1 pour tous les joueurs",
    "Sit in next open seat.": "S'installer dans la prochaine place vide",
    "Draw card on behalf of next empty seat.": "Tirer une carte pour la prochaine place vide",
    "Tokens": "Marqueurs",
    "Hover over the top/bottom half of the card when pressing the key to add/remove tokens.": "Passez la souris sur la moitié supérieure/inférieure d'une carte lorsque vous appuyez sur la touche pour ajouter/retirer des marqueurs.",
    "Card hotkeys": "Raccourcis de carte",
    "Hover over a card.": "Passez la souris sur une carte.",
    "Multiplayer hotkeys": "Raccourci multijoueur",
    "Useful for two-handed play.": "Utile pour le jeu à deux mains.",
    "Game hotkeys": "Raccourcis de jeu",
    "Tokens": "Marqueurs",
    "Hover over the top/bottom half of the card when pressing the key to add/remove tokens.": "Passez la souris sur la moitié supérieure/inférieure d'une carte lorsque vous appuyez sur la touche pour ajouter/retirer des marqueurs.",
    "Card hotkeys": "Raccourcis de carte",
    "Hover over a card.": "Passez la souris sur une carte.",
    "Game hotkeys": "Raccourci de jeu",
    "Multiplayer hotkeys": "Raccourcis multijoueur",
    "Useful for two-handed play.": "Utile pour le jeu à deux mains.",
	  
	  
	 //Encounter Deck window

 "Encounter Deck": "Deck de rencontre",
 "Discard until Enemy": "Défausser jusqu'à un Ennemi",
 "Discard until Location": "Défausser jusqu'à un Lieu",
 "Choose Random": "Choisir aléatoirement",
 "Go Back": "Retour",
 "My Deck": "Mon Deck",

    //Encounter Discard window

"Encounter Discard": "Pile de défausse du deck de rencontre",
"Shuffle into deck": "Remélanger dans le deck",
"Choose Random": "Choisir aléatoirement",
"Go Back": "Retour",
"My Deck": "Mon Deck",

    //Set Aside window

"Choose Random": "Choisir aléatoirement",
"Go Back": "Retour",
"My Deck": "Mon Deck",

    //Player card window

"Player card": "Carte du joueur",
"Owner's deck": "Deck du contrôleur",

    //Player hand window

"Player 1 hand": "Main du joueur 1",
"Player 2 hand": "Main du joueur 2",
"Player 3 hand": "Main du joueur 3",
"Player 4 hand": "Main du joueur 4",
"Choose Random": "Choisir aléatoirement",
"Go Back": "Retour",
"My Deck": "Mon Deck",

    //Load quest window

"Go Back": "Retour",
"Core Set & Shadows of Mirkwood": "Boîte de base et Ombres de la Forêt Noire",
"Khazad-dûm & Dwarrowdelf": "Khazad-dûm et Royaume de Cavenain",
"Heirs of Númenor & Against the Shadow": "Les Héritiers de Númenor et Face à l'Ombre",
"The Voice of Isengard & The Ringmaker": "L'Appel de l'Isengard et Le Créateur d'Anneaux",
"The Lost Realm & Angmar Awakened": "Le Royaume Perdu et Le Réveil d'Angmar",
"The Grey Havens & The Dreamchaser": "Les Havres Gris et Chasse-Rêve",
"The Sands of Harad & The Haradrim": "Les Sables de Harad et Les Haradrim",
"The Wilds of Rhovanion & Ered Mithrin": "Les Terres Sauvages du Rhovanion et Ered Mithrin",
"A Shadow in the East & Vengenace of Mordor": "Une Ombre à l'Est et La Vengeance du Mordor",
"The Hobbit": "Le Hobbit - Extention de Saga",
"The Lord of the Rings Standalone": "Le Seigneur des Anneaux - Extention de Saga",
"The Lord of the Rings Campaign": "Le Seigneur des Anneaux - Campagne étendue",
"Two-Player Limited-Edition Starter": "Two-Player Limited-Edition ",
"Print on Demand": "Scenarios indépendants",
"ALeP - Children of Eorl & Oaths of the Rohirrim": "ALeP - Les Enfants d'Eorl et Le Serment des Rohirrim",
"Passage Through Mirkwood": "Passage dans la Forêt Noire",
"Journey Along the Anduin": "Voyage le Long de l'Anduin",
"Escape from Dol Guldur": "Évasion de Dol Guldur",
"The Hunt for Gollum": "À la Poursuite de Gollum",
"Conflict at the Carrock": "Conflit au Carrock",
"A Journey to Rhosgobel": "Voyage à Rhosgobel",
"The Hills of Emyn Muil": "Les Collines d'Emyn Muil",
"The Dead Marshes": "Les Marais des Morts",
"Return to Mirkwood": "Retour à la Forêt Noire",
"Into the Pit": "Dans le Gouffre Noir",
"The Seventh Level": "Le Septième Niveau",
"Flight from Moria": "Fuir la Moria",
"The Redhorn Gate": "La Porte de Rubicorne",
"Road to Rivendell": "La Route vers Fondcombe",
"The Watcher in the Water": "Le Guetteur de l'Eau",
"The Long Dark": "La Longue Obscurité",
"Foundations of Stone": "Fondations de Pierre",
"Shadow and Flame": "L'Ombre et la Flamme",
"Peril In Pelargir": "Bagarre à Pelargir",
"Into Ithilien": "En Ithilien",
"The Siege of Cair Andros": "Le Siège de Cair Andros",
"The Steward's Fear": "Les Craintes de l'Intendant",
"The Drúadan Forest": "La Forêt de Drúadan",
"Encounter at Amon Dîn": "Rencontre à Amon Dîn",
"Assault on Osgiliath": "Assaut sur Osgiliath",
"The Blood of Gondor": "Le Sang du Gondor",
"The Morgul Vale": "La Vallée de Morgul",
"Fords of Isen": "Les Gués de l'Isen",
"To Catch an Orc": "Chasse à l'Orque",
"Into Fangorn": "À Fangorn",
"The Dunland Trap": "Piégé au Pays de Dun !",
"The Three Trials": "Les Trois Épreuves",
"Trouble in Tharbad": "Panique à Tharbad",
"The Nin in Eilph": "Les Nîn-in-Eilph",
"Celebrimbor's Secret": "Le Secret de Celebrimbor",
"The Antlered Crown": "La Couronne d'Andouillers",
"Intruders in Chetwood": "Intrus au Bois de Chet",
"The Weather Hills": "Les Collines Venteuses",
"Deadmen's Dike": "La Chaussée des Morts",
"The Wastes of Eriador": "Les Désolations d'Eriador",
"Escape from Mount Gram": "Fuite du Mont Gram",
"Across the Ettenmoors": "La Traversée des Landes d'Etten",
"The Treachery of Rhudaur": "La Perfidie du Rhudaur",
"The Battle of Carn Dum": "La Bataille de Carn Dûm",
"The Dread Realm": "Le Royaume de l'Effroi",
"Voyage Across Belegaer": "Voyage sur la Belegaer",
"The Fate of Numenor": "Le Destin de Númenor",
"Raid on the Grey Havens": "Raid sur les Havres Gris",
"Flight of the Stormcaller": "La Fuite de La Tourmente",
"The Thing in the Depths": "La Chose des Profondeurs",
"Temple of the Deceived": "Temple des Dupes",
 "The Drowned Ruins": "Les Ruines Immergées",
"A Storm on Cobas Haven": "Une Tempête sur le Havre de Cobas",
"The City of Corsairs": "La Cité des Corsaires",
"Escape from Umbar": "Fuite d'Umbar",
"Desert Crossing": "La Traversée du Désert",
"The Long Arm of Mordor": "Le Souffle du Mordor",
"The Mumakil": "Les Mûmakil",
"Race Across Harad": "Course en Harad",
"Beneath the Sands": "Sous les Sables",
"The Black Serpent": "Le Serpent Noir",
"The Dungeons of Cirith Gurat": "Les Cachots de Cirith Gurat",
"The Crossings of Poros": "Les Gués du Poros",
"Journey Up the Anduin": "La Remontée de l'Anduin",
"Lost in Mirkwood": "Perdus dans la Forêt Noire",
"The King's Quest": "La Quête du Roi",
"The Withered Heath": "La Brande Désséchée",
"Roam Across Rhovanion": "Errance au Rhovanion",
"Fire in the Night": "Des Flammes dans la Nuit",
"The Ghost of Framsburg": "Le Fantôme de Framsbourg",
"Mount Gundabad": "Mont Gundabad",
"The Fate of Wilderland": "Le Destin des Terres Sauvages",
"The River Running": "La Rivière Courante",
"Danger in Dorwinian": "Danger au Dorwinion",
"Temple of Doom": "Le Temple Maudit",
"Wrath and Ruin": "Courroux Destructeur",
"The City of Ulfast": "La Cité d'Ulfast",
"Challenge of the Wainriders": "Le Défi des Gens-des-Chariots",
"Under the Ash Mountains": "Sous les Monts de Cendre",
"The Land of Sorrow": "La Terre des Lamentations",
"The Fortress of Nurn": "La Forteresse de Nurn",
"We Must Away Ere Break of Day": "Il nous Faut Aller avant le Lever du Jour",
"Over the Misty Mountains Grim": "Au-delà des Montagnes Froides et Embrumées",
"Dungeons Deep and Caverns Dim": "Des Cachots Profonds et des Cavernes Obscures",
"Flies and Spiders": "Mouches et Araignées",
"The Lonely Mountain": "Le Mont Solitaire",
"The Battle of Five Armies": "La Bataille des Cinq Armées",
"A Shadow of the Past": "L'Ombre du Passé",
"The Old Forest": "La Vieille Forêt",
"Fog on the Barrow downs": "Brouillard sur les Hauts des Galgals",
"A Knife in the Dark": "Un Poignard dans le Noir",
"Flight to the Ford": "Fuite vers le Gué",
"The Ring Goes South": "L'Anneau Prend le Chemin",
"Journey in the Dark": "Un Voyage dans l'Obscurité",
"Breaking of the Fellowship": "La Dissolution de la Communauté",
"The Uruk hai": "Les Uruk-hai",
"Helm's Deep": "Le Gouffre de Helm",
"The Road to Isengard": "La Route de l'Isengard",
"The Passage of the Marshes": "La Traversée des Marais",
"Journey to the Cross roads": "Voyage à la Croisée des Chemins",
"Shelob's Lair": "L'Antre d'Arachne",
"The Passing of the Grey Company": "Le Passage de la Compagnie Grise",
"The Siege of Gondor": "Le Siège du Gondor",
"The Battle of the Pelennor Fields": "La Bataille des Champs du Pelennor",
"The Tower of Cirith Ungol": "La Tour de Cirith Ungol",
"The Black Gate Opens": "La Porte Noire s'Ouvre",
"Mount Doom": "Montagne du Destin",
"ALeP The Scouring of the Shire": "ALeP Le Nettoyage de la Comté",
"The Oath": "La Promesse",
"The Caves of Nibin Dum": "Les Cavernes de Nibin-dûm",
"The Massing at Osgiliath": "Rassemblement à Osgiliath",
"The Battle of Lake Town": "La Bataille de la Ville du Lac",
"The Stone of Erech": "La Pierre d'Erech",
"The Old Forest": "La Vieille Forêt",
"Fog on the Barrow downs": "Brouillard sur les Hauts des Galgals",
"The Ruins of Belegost": "Las Ruinas de Belegost",
"Murder at the Prancing Pony": "Meurtre au Poney Fringant",
"Standard The Siege of Annuminas": "Le Siège d'Annúminas - Standard",
"Epic The Siege of Annuminas": "Le Siège d'Annúminas - Épique",
"Attack on Dol Guldur": "Attaque sur Dol Guldur",
"The Wizard's Quest": "La Quête du Magicien",
"The Woodland Realm": "Le Royaume Sylvestre",
"The Mines of Moria": "Las Minas de Moria",
"Escape from Khazad dûm": "Huida de Khazad-dûm",
"The Hunt for the Dreadnaught": "La Caza del Dreadnaught",
"ALeP The Scouring of the Shire": "ALeP Le Nettoyage de la Comté",
"ALeP Ambush at Erelas": "ALeP Embuscade à Erelas",
"ALeP The Battle for the Beacon": "ALeP La Bataille pour le Feu d'Alarme",
"ALeP The Horse Lord s Ire": "ALeP La Colère des Seigneurs des Chevaux",
"ALeP The Aldburg Plot": "ALeP Complot à Aldburg",
"ALeP Fire on the Eastemnet": "ALeP Feu dans l'Estemnet",
"ALeP The Gap of Rohan": "ALeP La Trouée du Rohan",
"ALeP The Glittering Caves": "ALeP Les Cavernes Scientillantes",

  },
}
